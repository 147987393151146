import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserNeedPartnerView, QuoteResponseContactView, SendQuoteDialog } from 'modules/quote'
import { useNeed } from 'modules/need'
import { QuoteType, UserNeed } from 'modules/api'
import { Page, PageHeader } from 'modules/ui'
import { useProfile } from 'modules/profile'

interface Props {
  origin?: 'weddings' | 'quotes'
}

const NeedView = ({ origin = 'quotes' }: Props) => {
  const { userNeed }: { userNeed: UserNeed } = useNeed()
  const history = useHistory()
  const images = (userNeed?.images || []).map(image => image.name)
  const [formData, setFormData] = useState({})
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const {
    user: { wallet },
  } = useProfile()

  const handleAcceptQuoteRequest = data => {
    setFormData(data)
    setIsDialogOpen(true)
  }

  const handleSendQuoteDialogSuccess = () => {
    history.push('/quotes/sent')
  }

  const handleSendQuoteDialogCancel = () => {
    setIsDialogOpen(false)
  }

  const handleClickBack = () => {
    history.goBack()
  }

  if (userNeed === null) {
    return null
  }

  return (
    <>
      <PageHeader
        description="This couple is waiting for quotes and enquiries with the details below, so you have the opportunity to contact them!"
        onClickBack={handleClickBack}
      />

      <Page maxWidth="lg">
        <UserNeedPartnerView
          questionResponses={userNeed.questionResponses}
          couple={userNeed.wedding.couple}
          needName={userNeed.need?.name || ''}
          createdAt={userNeed.approvedAt}
          wedding={userNeed.wedding}
          text={userNeed?.adminText ?? userNeed?.text}
          quoteType={QuoteType.Wall}
          quotesCount={userNeed.quotesCount}
          attachments={images}
          onClickGoBack={handleClickBack}
        />

        <QuoteResponseContactView
          balance={wallet.balance}
          quoteType={QuoteType.Wall}
          onAcceptQuoteRequest={handleAcceptQuoteRequest}
          onCancel={handleClickBack}
        />
      </Page>

      <SendQuoteDialog
        formData={formData}
        open={isDialogOpen}
        onSuccess={handleSendQuoteDialogSuccess}
        onCancel={handleSendQuoteDialogCancel}
      />
    </>
  )
}

export default NeedView
