import { ReactNode } from 'react'
import moment from 'moment'
import { QuoteRequest, QuoteRequestStatus, QuoteType, SortDirection } from 'modules/api'
import { Page, Table } from 'modules/ui'
import { useT } from 'modules/i18n'

const formatStatus = (quoteStatus: QuoteRequestStatus) => {
  const t = useT()

  switch (quoteStatus) {
    case QuoteRequestStatus.Accepted:
      return t('Accepted')
    case QuoteRequestStatus.Pending:
      return t('Pending')
    case QuoteRequestStatus.Rejected:
      return t('Rejected')
  }
}

const formatType = (quoteType: QuoteType) => {
  const t = useT()

  switch (quoteType) {
    case QuoteType.Direct:
      return t('Direct request')
    case QuoteType.Wall:
      return t('From weddings page')
    case QuoteType.ForYou:
      return t('By recommendation')
  }
}

export const createColumns = (RowActionComponent: ReactNode) => [
  {
    field: 'type',
    headerName: 'Type of quote',
    valueGetter: row => formatType(row.type),
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    valueGetter: row => moment(row.createdAt).format('YYYY.MM.DD HH:mm'),
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: row => formatStatus(row.status),
  },
  {
    field: 'category',
    headerName: 'Category',
    valueGetter: row => {
      // old quote requests dont have userneedcopy
      // this is a temporary fix
      if (row.userNeedCopy?.need?.name) {
        return row.userNeedCopy?.need?.name
      }

      console.warn('Kategória nem található')
      return 'Kategória nem található'
    },
    sortable: true,
  },
  {
    field: 'coupleName',
    headerName: 'Couple name',
    valueGetter: row => row.couple.name,
    sortable: true,
  },
  {
    field: 'coupleEmail',
    headerName: 'Couple email',
    valueGetter: row => row.couple.email,
    sortable: true,
  },
  {
    field: 'partnerName',
    headerName: 'Partner name',
    valueGetter: row => row.partner.name,
    sortable: true,
  },
  {
    field: '',
    headerName: 'Actions',
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActionComponent,
  },
]

export interface QuoteAdminListProps {
  quoteRequests: QuoteRequest[]
  total: number
  pageSize: number
  page: number
  filter: string
  sortBy: string
  sortDirection: SortDirection
  rowActionsComponent: ReactNode
  onChangePage: (page: number) => void
  onChangeRowsPerPage: (pageSize: number) => void
  onSearch: (filter: string) => void
  onSort: (newSortBy: string, sortBy: string, sortDirection: SortDirection) => void
}

const QuoteAdminList = ({
  quoteRequests,
  total,
  pageSize,
  page,
  filter,
  sortBy,
  sortDirection,
  rowActionsComponent,
  onChangePage,
  onChangeRowsPerPage,
  onSearch,
  onSort,
}: QuoteAdminListProps) => {
  const columns = createColumns(rowActionsComponent)

  return (
    <Page>
      <Table
        title="Quotes"
        data={quoteRequests || []}
        columns={columns}
        totalElements={total}
        pageSize={pageSize}
        page={page}
        handleChangePage={onChangePage}
        handleChangeRowsPerPage={onChangeRowsPerPage}
        toggleSort={onSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        handleSearch={onSearch}
        filterTypes={[
          ['Category', 'category'],
          ['Couple name', 'coupleName'],
          ['Couple email', 'coupleEmail'],
          ['Partner name', 'partnerName'],
          ['Partner email', 'partnerEmail'],
        ]}
      />
    </Page>
  )
}

export default QuoteAdminList
