import { Grid, MenuItem } from '@material-ui/core'
import BaseSelect, { SelectProps } from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

interface PickerProps extends SelectProps {
  options: {
    name: string
    value: string
  }[]
}

const useStyles = makeStyles(theme => ({
  fill: {
    width: '100%',
  },
  container: {
    maxWidth: '300px',
    marginBottom: theme.spacing(3),
  },
}))

function Picker({ options, ...props }: PickerProps) {
  const classes = useStyles()

  return (
    <Grid className={classes.container}>
      <BaseSelect className={classes.fill} {...props} defaultValue="all">
        {options.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </BaseSelect>
    </Grid>
  )
}

export default Picker
