import { useHistory } from 'react-router-dom'
import { Box, Chip, makeStyles } from '@material-ui/core'
import { Page, PageHeader } from 'modules/ui'
import { useT } from 'modules/i18n'
import { QuoteResponseForm, UserNeedPartnerView, useQuote } from 'modules/quote'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: `${spacing(2)}px 0`,
  },
  backButton: {
    margin: spacing(3),
    padding: spacing(1.5),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: spacing(4),
    border: '1px solid lightGrey',
  },
  arrowBack: {
    marginRight: spacing(1),
    color: palette.primary.main,
  },
  chip: {
    color: 'white',
    fontWeight: 700,
  },
}))

const Sent = () => {
  const history = useHistory()
  const classes = useStyles()
  const t = useT()
  const { quoteRequest } = useQuote()

  const quoteResponse = quoteRequest?.quoteResponse
  const seenAt = quoteResponse?.seenAt

  const handleClickBack = () => {
    history.goBack()
  }

  if (!quoteResponse) {
    return null
  }

  return (
    <>
      <PageHeader
        description="Here you find the details of the original quote and wedding, as well as your response"
        onClickBack={handleClickBack}
      />
      <Page maxWidth="lg">
        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Chip
            className={classes.chip}
            label={t(seenAt ? 'Already seen' : 'Not yet seen')}
            size="small"
            style={{ backgroundColor: seenAt ? '#59de74' : '#dea01d' }}
          />
        </Box>

        <UserNeedPartnerView
          questionResponses={quoteRequest.userNeedCopy?.questionResponses}
          couple={quoteRequest.couple}
          needName={quoteRequest.userNeedCopy?.need?.name || ''}
          createdAt={quoteRequest.createdAt}
          wedding={quoteRequest.weddingCopy}
          text={quoteRequest.text}
          quoteType={quoteRequest.type}
          attachments={quoteRequest.attachments || []}
          onClickGoBack={handleClickBack}
        />

        <QuoteResponseForm
          readonly
          initialValues={{
            text: quoteResponse.text || '',
            attachments: quoteResponse?.attachments || [],
            sendEmail: quoteResponse.sendEmail || false,
          }}
        />
      </Page>
    </>
  )
}

export default Sent
