import moment from 'moment'
import { ImageCell, Table, TableProps } from 'modules/ui'
import { Couple } from 'modules/api'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const createColumns = ({ RowActions }) => [
  {
    field: 'id',
    headerName: 'ID',
    width: 180,
  },
  {
    field: 'profileImage',
    headerName: 'Profile image',
    width: 150,
    renderCell: ({ profileImage }) => (
      <ImageCell src={`${imagesPath}/${profileImage}`} alt="profile picture" />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 220,
  },
  {
    field: 'createdAt',
    headerName: 'Registered at',
    width: 220,
    valueGetter: ({ createdAt }) => moment(createdAt).format('YYYY-MM-DD HH:mm'),
  },
  {
    field: 'lastSeenAt',
    headerName: 'Last seen at',
    width: 220,
    valueGetter: ({ lastSeenAt }) =>
      lastSeenAt ? moment(lastSeenAt).format('YYYY-MM-DD HH:mm') : '-',
  },
  {
    field: 'weddingDate',
    headerName: 'Wedding date',
    width: 220,
    valueGetter: ({ wedding }) => moment(wedding.date).format('YYYY-MM-DD'),
  },
  {
    field: 'weddingAddress',
    headerName: 'Wedding address',
    width: 220,
    valueGetter: ({ wedding }) =>
      wedding?.address?.name || wedding.possibleAddresses.map(({ name }) => name).join(', ') || '-',
  },
  {
    field: 'likedItems',
    headerName: 'Liked items',
    width: 220,
    valueGetter: ({ likedItems }) => likedItems.length,
  },
  {
    field: 'likedPartners',
    headerName: 'Liked partners',
    width: 220,
    valueGetter: ({ likedPartners }) => likedPartners.length,
  },
  {
    field: 'numberOfUserNeeds',
    headerName: 'Number of UserNeeds',
    width: 100,
    valueGetter: ({ userNeeds }) => userNeeds.length,
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export interface CoupleListProps extends Omit<TableProps, 'columns'> {
  data: Couple[]
  rowActionsComponent?: React.FC<{ row: Couple }>
}

const CoupleList = ({ rowActionsComponent: RowActions, ...props }: CoupleListProps) => {
  const columns = createColumns({ RowActions })

  return <Table columns={columns} {...props} />
}

export default CoupleList
