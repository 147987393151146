import { gql } from 'graphql-tag'

export const quoteRequestQuery = gql`
  query QuoteRequest($quoteRequestId: ID!) {
    quoteRequest(id: $quoteRequestId) {
      id
      attachments
      createdAt
      type
      quoteResponse {
        id
        status
        reasons
        text
        seenAt
        feedback {
          option
        }
        sendEmail
        attachments {
          name
          hash
        }
      }
      couple {
        id
        name
        myName
        partnersName
        emailFragment
        lastSeenAt
      }
      weddingCopy {
        date
        address {
          name
        }
        possibleYear
        possibleMonths
        possibleDays
        possibleAddresses {
          name
        }
        guestRange {
          low
          high
        }
        locationTags {
          name
        }
      }
      text
      status
      partnerId
      partner {
        name
      }
      coupleId
      createdAt
      userNeedCopy {
        need {
          name
        }
        questionResponses {
          TEXT
          TEXT_LONG
          NUMBER
          TIME
          DATE
          CURRENCY
          RANGE {
            low
            high
          }
          RANGE_CURRENCY {
            low
            high
          }
          CHECK_GROUP
          CHECK_GROUP_INLINE
          CHECK
          RADIO_GROUP
          question {
            id
            title
            fields {
              label
            }
          }
        }
      }
    }
  }
`

export const quoteRequestsQuery = gql`
  query QuoteRequests($filter: QuoteRequestFilter!) {
    quoteRequests(filter: $filter) {
      total
      elements {
        id
        type
        seenAt
        conversation {
          id
        }
        attachments
        partner {
          name
        }
        text
        status
        createdAt
        quoteResponse {
          id
          seenAt
          createdAt
          feedback {
            option
          }
        }
        weddingCopy {
          date
          address {
            name
          }
          guestRange {
            low
            high
          }
          possibleYear
          possibleAddresses {
            name
          }
        }
        couple {
          id
          myName
          partnersName
          name
          profileImage {
            name
          }
          email
          emailFragment
          lastSeenAt
        }
        userNeedCopy {
          need {
            name
            category {
              image {
                name
              }
            }
          }
        }
      }
    }
  }
`

export const unseenQuoteRequestsQuery = gql`
  query UnseenQuoteRequests {
    unseenQuoteRequests
  }
`

export const quoteResponseQuery = gql`
  query QuoteResponse($id: ID) {
    quoteResponse(id: $id) {
      id
      attachments {
        name
        hash
      }
      partner {
        name
        contactEmail
        phone
      }
      name
      partnerId
      coupleId
      email
      phone
      text
      status
      reasons
      createdAt
    }
  }
`

export const acceptQuoteRequestMutation = gql`
  mutation AcceptQuoteRequest($input: NewQuoteResponseInput!) {
    acceptQuoteRequest(input: $input) {
      id
    }
  }
`

export const declineQuoteRequestMutation = gql`
  mutation RejectQuoteRequest($input: NewQuoteResponseInput!) {
    rejectQuoteRequest(input: $input) {
      id
    }
  }
`

export const partnerQuoteMutation = gql`
  mutation PartnerQuote($quoteRequestInput: PartnerQuoteInput!) {
    partnerQuote(quoteRequestInput: $quoteRequestInput) {
      id
    }
  }
`

export const seeQuoteRequestMutation = gql`
  mutation SeeQuoteRequest($input: ID!) {
    seeQuoteRequest(input: $input) {
      id
    }
  }
`
