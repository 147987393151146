import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
// import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import Box from '@material-ui/core/Box'
import { NavButton, Picker, useTable } from 'modules/ui'
import { PaymentsList, usePayment } from 'modules/payment'
import { Page } from 'modules/ui'
import { getPaginationParams, useQueryState } from 'modules/app'
import { PaymentStatus } from 'modules/api'

// const RowActions = ({ row }) => {
//   const match = useRouteMatch()

//   return (
//     <>
//       <NavButton to={`${match.url}/${row.id}/edit`} tooltip="Edit">
//         <EditIcon fontSize="small" />
//       </NavButton>
//       <NavButton to={`${match.url}/${row.id}/remove`} tooltip="Delete">
//         <DeleteIcon fontSize="small" />
//       </NavButton>
//     </>
//   )
// }

const statusPickerOptions = [
  { name: 'All', value: 'all' },
  { name: 'Pending', value: PaymentStatus.Pending },
  { name: 'Success', value: PaymentStatus.Success },
  { name: 'Failed', value: PaymentStatus.Failed },
]

const RowActions = undefined

const List = () => {
  const { payments, total } = usePayment()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()
  const { handleChange, value } = useQueryState({
    defaultValue: 'all',
    queryParam: 'status',
  })

  return (
    <Page fullHeight>
      <Picker options={statusPickerOptions} onChange={handleChange} value={value} />
      <PaymentsList
        title="Payments list"
        data={payments}
        rowActionsComponent={RowActions}
        pageSize={pageSize}
        totalElements={total}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        handleSearch={handleSearch}
      />
    </Page>
  )
}

export default List
