import moment from 'moment'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Chip, ChipProps, Typography as UITypography } from '@material-ui/core'
import { EventNoteOutlined, LocationOnOutlined, PeopleOutlined } from '@material-ui/icons'
import config from 'modules/config'
import { useT } from 'modules/i18n'
import { UserNeedCopy, Wedding, QuoteType, Couple } from 'modules/api'
import { Typography, OpenableImage } from 'modules/ui'
import { formatPlannedDate, formatPlannedLocation } from '../formatters'
import CustomNeedList from './CustomNeedList'

const imagesPath = config.app.IMAGES_URL
const attachmentImageSize = 120

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  needName: {
    fontSize: '1.5rem',
  },
  quote: {
    backgroundColor: theme.palette.background.paper,
    alignItems: 'flex-start',
    margin: 'auto',
    padding: theme.spacing(1),
    height: 'calc(100vh - 120px)',
  },
  needIcon: {
    color: 'grey',
    margin: theme.spacing(2),
  },
  label: {
    '&::after': {
      content: '": "',
      display: 'inline',
    },
  },
  quoteChip: {
    color: 'white',
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  needDetails: {},
  attachmentImages: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    margin: `${theme.spacing(2)}px 0`,
  },
  attachmentImage: {
    width: attachmentImageSize,
    height: attachmentImageSize,
    objectFit: 'cover',
    borderRadius: theme.spacing(3),
  },
}))

interface QuoteChipProps extends ChipProps {
  quotesCount: number
  quoteType: QuoteType
}

const QuoteChip = ({ quotesCount, quoteType, ...props }: QuoteChipProps) => {
  const theme = useTheme()
  const t = useT()
  let label, color

  switch (quoteType) {
    case QuoteType.Wall:
      if (quotesCount < 5) label = t('Be in the first five')
      else if (quotesCount < 3) label = t('Be in the first three')
      else if (quotesCount === 0) label = t('Be the first')
      else label = t('Weddings Wall')

      color = theme.palette.warning.light
      break

    case QuoteType.Direct:
      label = t('Direct quote request')
      color = theme.palette.warning.light
      break

    case QuoteType.ForYou:
      label = t('By recommendation')
      color = '#00D3FF'
      break

    default:
      return null
  }

  return <Chip {...props} size="small" label={label} style={{ background: color }} />
}

const getCaptionText = (quoteType: QuoteType, quotesCount: number) => {
  switch (quoteType) {
    case QuoteType.Direct:
      return 'This couple directly asked you for a quote through your profile!'
    case QuoteType.ForYou:
      return 'This couple requested a quote directly from you through the recommendation system'
    case QuoteType.Wall:
      if (quotesCount === 0) {
        return 'Be the first to send a quote to the couple!'
      }
      if (quotesCount < 3) {
        return 'Be in the first three to send a quote to the couple!'
      }
      if (quotesCount < 5) {
        return 'Be in the first five to send a quote to the couple!'
      }
  }
}

type UserNeedPartnerViewProps = {
  questionResponses: UserNeedCopy['questionResponses']
  couple: Couple
  createdAt: string
  needName: string
  wedding: Wedding
  text: string
  quoteType: QuoteType
  attachments: string[]
  quotesCount?: number
  onClickGoBack: () => void
}

const UserNeedPartnerView = ({
  questionResponses = [],
  couple,
  createdAt,
  needName,
  wedding,
  quoteType,
  text,
  attachments,
  quotesCount,
}: UserNeedPartnerViewProps) => {
  const classes = useStyles()
  const t = useT()

  return (
    <>
      <Box>
        <Typography variant="h5">
          {couple.name}{' '}
          <Typography className={classes.needName} display="inline" color="textSecondary">
            {`${needName} ${t('looking for service')}`}
          </Typography>
        </Typography>

        <Typography color="textSecondary" variant="caption">
          {couple.email}
        </Typography>

        <Box>
          <Typography display="inline" color="textSecondary">
            {quoteType === QuoteType.Wall ? 'Time of creation:' : 'Receive time:'}
          </Typography>
          <UITypography display="inline" color="textSecondary" component="span">
            {' ' + moment(createdAt).format('YYYY.MM.DD HH:mm')}
          </UITypography>
        </Box>

        {couple.lastSeenAt && (
          <Box>
            <Typography display="inline" color="textSecondary">
              Last sign in date:
            </Typography>
            <UITypography display="inline" color="textSecondary" component="span">
              {' ' + moment(couple.lastSeenAt).format('YYYY.MM.DD HH:mm')}
            </UITypography>
          </Box>
        )}
      </Box>

      <Box display="flex" alignItems={'center'} mt={2} mb={2}>
        <QuoteChip quotesCount={quotesCount} quoteType={quoteType} className={classes.quoteChip} />
        <Typography variant="caption" color="textSecondary">
          {getCaptionText(quoteType, quotesCount)}
        </Typography>
      </Box>

      <Box mt={2} mb={2}>
        <Typography variant="h6">Default params</Typography>
        <Typography color="textSecondary">
          You can see the default informations about the wedding here
        </Typography>

        <Box display="flex" alignItems={'center'}>
          <EventNoteOutlined fontSize="large" className={classes.needIcon} />
          <Box className={classes.needDetails}>
            <Typography component="span" color="textSecondary" className={classes.label}>
              {wedding.date ? 'Date' : 'Planned date (Suitable months and days)'}
            </Typography>
            <Typography component="span">
              {wedding.date
                ? moment(wedding.date).format('YYYY-MM-DD')
                : formatPlannedDate(wedding, t)}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems={'center'}>
          <LocationOnOutlined fontSize="large" className={classes.needIcon} />
          <Box>
            <Typography component="span" color="textSecondary" className={classes.label}>
              {wedding.address ? 'Location' : 'Planned location(s) and favorite features'}
            </Typography>
            <Typography component="span">
              {wedding.address?.name || formatPlannedLocation(wedding)}
            </Typography>
          </Box>
        </Box>

        {wedding.guestRange && (
          <Box display="flex" alignItems={'center'}>
            <PeopleOutlined fontSize="large" className={classes.needIcon} />
            <Box>
              <Typography component="span" color="textSecondary" className={classes.label}>
                Guests
              </Typography>
              <Typography component="span">
                {wedding.guestRange.low}-{wedding.guestRange.high}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6">Custom params</Typography>
        <Typography color="textSecondary">
          You can see the couples special needs about the service here
        </Typography>
        <CustomNeedList questionResponses={questionResponses} />
      </Box>

      <Box>
        <Typography variant="h6">Textual addition</Typography>
        <Typography color="textSecondary">The description which is given by couple:</Typography>
        <Box mt={2} mb={2}>
          <Typography>{text || '-'}</Typography>
        </Box>
      </Box>

      {attachments.length > 0 && (
        <Box mb={1} display="flex" flexDirection="column">
          <Typography variant="h6">Images attached by the couple</Typography>
          <Typography color="textSecondary">
            Here you can find the images attached by the couple
          </Typography>
          <Box className={classes.attachmentImages}>
            {attachments.map(a => (
              <OpenableImage
                key={a}
                className={classes.attachmentImage}
                queryParams={`height=${attachmentImageSize}`}
                src={`${imagesPath}/${a}`}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}

export default UserNeedPartnerView
