import { Grow } from '@material-ui/core'
import moment from 'moment'
import { useT } from 'modules/i18n'
import config from 'modules/config'
import QuoteRequestCard from './QuoteRequestCard'
import { Need, QuoteRequestStatus, Wedding, Couple, Maybe, Range, WeddingCopy } from 'modules/api'

const imagesPath = config.app.IMAGES_URL

interface Props {
  wedding: Wedding | WeddingCopy
  need?: Need
  couple: Couple
  isNew?: boolean
  onDetailsClick: any
  status?: QuoteRequestStatus
  typeBadge?: {
    label: string
    info: string
    chipBackground?: string
    infoColor?: string
  }
  style?: object
  bottomText?: string
}

const getWeddingDate = (wedding: Wedding | WeddingCopy, t: any): string => {
  if (wedding.date) {
    return moment(wedding.date).format('YYYY-MM-DD')
  }
  return `${t('Planned year')}: ${wedding.possibleYear}`
}

const getGuestRange = (guestRange: Maybe<Range>, t: any): string => {
  if (guestRange?.low && guestRange?.high) {
    return `${guestRange?.low}－${guestRange?.high} ${t('guests')}`
  }
  return ''
}

const getLocation = (wedding: Wedding | WeddingCopy, t: any) => {
  if (wedding.address?.name) {
    return wedding.address.name
  }
  return `${t('Planned city')}: ${wedding.possibleAddresses.map(a => a.name).join(', ')}`
}

const QuotesCardListItem = ({
  wedding,
  need = null,
  couple,
  isNew = false,
  onDetailsClick,
  status = null,
  typeBadge = null,
  bottomText,
  style = {},
}: Props) => {
  const t = useT()
  const imageName = need?.category.image.name

  return (
    <Grow in>
      <div>
        <QuoteRequestCard
          name={couple.name}
          email={couple.emailFragment}
          image={imageName ? `${imagesPath}/${imageName}` : null}
          needTitle={need?.name || ''}
          isNew={isNew}
          date={getWeddingDate(wedding, t)}
          guestRange={getGuestRange(wedding.guestRange, t)}
          location={getLocation(wedding, t)}
          onDetailsClick={onDetailsClick}
          status={status}
          typeBadge={typeBadge}
          style={style}
          bottomText={bottomText}
        />
      </div>
    </Grow>
  )
}

export default QuotesCardListItem
