import { Grid, makeStyles } from '@material-ui/core'
import { QuoteRequest } from 'modules/api'
import { QuoteAdminList, useQuote } from 'modules/quote'
import { useT } from 'modules/i18n'
import { getPaginationParams } from 'modules/app'
import { NavButton, useTable } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  navButton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}))

const AdminRowActionsComponent = (row: QuoteRequest) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Grid container wrap="nowrap">
      <NavButton to={`/quotes/${row.id}/view`} tooltip="View" className={classes.navButton}>
        {t('View')}
      </NavButton>
      <NavButton to={`/couples/${row.couple.id}/view`} tooltip="CIC" className={classes.navButton}>
        {t('CIC')}
      </NavButton>
    </Grid>
  )
}

const List = () => {
  // todo add types for hooks like useQuote
  const { quoteRequests, total } = useQuote()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()

  return (
    <QuoteAdminList
      quoteRequests={quoteRequests}
      total={total}
      pageSize={pageSize}
      page={page}
      filter={filter}
      sortBy={sortBy}
      sortDirection={sortDirection}
      rowActionsComponent={AdminRowActionsComponent}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onSearch={handleSearch}
      onSort={handleSort}
    />
  )
}

export default List
