import { matchPath } from 'react-router'
import { delay, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { putAsync } from 'saga-toolkit'
import { LOCATION_CHANGE } from 'connected-react-router'

import { actions as adminActions } from 'modules/admin'
import {
  actions as appActions,
  selectors as appSelectors,
  getPaginationParams,
  getQueryParams,
} from 'modules/app'
import {
  actions as availabilityActions,
  selectors as availabilitySelectors,
} from 'modules/availability'
import { actions as authActions } from 'modules/auth'
import { actions as blogActions, selectors as blogSelectors } from 'modules/blog'
import { actions as categoryActions, selectors as categorySelectors } from 'modules/category'
import { actions as chatActions } from 'modules/chat'
import { actions as coupleActions } from 'modules/couple'
import { actions as coverActions } from 'modules/cover'
import { actions as highlightActions, selectors as highlightSelectors } from 'modules/highlight'
import { actions as inspirationActions } from 'modules/inspiration'
import { actions as itemActions, selectors as itemSelectors } from 'modules/item'
import {
  actions as notificationActions,
  selectors as notificationSelectors,
} from 'modules/notification'
import { actions as partnerActions, selectors as partnerSelectors } from 'modules/partner'
import { actions as needActions, selectors as needSelectors } from 'modules/need'
import { actions as profileActions, selectors as profileSelectors } from 'modules/profile'
import { actions as quoteActions, selectors as quoteSelectors } from 'modules/quote'
import { actions as tagActions, selectors as tagSelectors } from 'modules/tag'
import { actions as tagGroupActions, selectors as tagGroupSelectors } from 'modules/tagGroup'
import { actions as trackingActions } from 'modules/tracking'
import { actions as userActions, selectors as userSelectors } from 'modules/user'
import { actions as weddingActions, selectors as weddingSelectors } from 'modules/wedding'
import { actions as transactionActions } from 'modules/transaction'
import { actions as conversationActions } from 'modules/conversation'
import { actions as paymentActions } from 'modules/payment'
import { QuoteRequestStatus, QuoteType, UserNeedStatus, UserType } from 'modules/api'

import { mapNavigationData } from '../mapNavigationData'

function* locationChange({ payload }) {
  try {
    const { location, isFirstRendering } = payload
    const { pathname } = location

    const started = yield select(appSelectors.selectStarted)
    const pathParts = pathname.split('/')
    const id = pathParts[2]

    const { eventName, data: trackingData } = mapNavigationData(pathname, location)

    yield put(trackingActions.track(eventName, trackingData))

    switch (true) {
      //HOME
      case ['/', '/home'].includes(pathname): {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const currentUser = (yield select(profileSelectors.selectRoot)).user
        if (currentUser?.type === UserType.Partner) {
          const needIds = currentUser.needs.map(need => need.id) || []
          yield put(needActions.fetchUserNeeds({ needIds }))
        }

        yield put(profileActions.fetchProfile())

        // const currentUser = (yield select(profileSelectors.selectRoot)).user

        // if (currentUser?.type === 'Admin') {
        //   yield put(adminActions.fetchHealthCheck()) // TODO fix taking too long
        // }
        break
      }

      // case pathname.startsWith('/admin'): {
      //   if (!started) {
      //     yield take(appActions.start.fulfilled)
      //   }

      //   const currentUser = (yield select(profileSelectors.selectRoot)).user

      //   if (currentUser?.type !== UserType.Admin) {
      //     return
      //   }

      //   yield put(adminActions.fetchHealthCheck())
      //   break
      // }

      //WEDDINGS
      case pathname.startsWith('/weddings'):
        if (!started) {
          yield take(appActions.start.fulfilled)
        }
        const { limit, offset } = getPaginationParams({ pageSize: 12 })
        const currentUser = (yield select(profileSelectors.selectRoot)).user
        const needIds = currentUser.needs.map(need => need.id) || []

        yield put(needActions.fetchUserNeeds({ limit, offset, needIds }))
        yield put(
          weddingActions.fetchUnseenWeddings({
            status: UserNeedStatus.Accepted,
            needIds,
          }),
        )

        if (pathname.endsWith('/need')) {
          yield put(needActions.seenUserNeed({ id }))
          yield put(needActions.fetchUserNeedForPartner({ id }))
          break
        }

        if (pathname.endsWith('/send')) {
          yield put(profileActions.fetchProfile())
          yield put(needActions.fetchUserNeedForPartner({ id }))
          break
        }
        break

      // WEDDING
      case pathname.startsWith('/wedding'):
        yield put(weddingActions.clearWedding())
        const wedding = yield select(weddingSelectors.selectWedding)

        if (wedding?.id === +id) {
          break
        }

        yield put(weddingActions.fetchWedding({ id }))
        break

      // ITEM
      case pathname === '/items':
        yield put(itemActions.clearItem())
        yield put(itemActions.fetchItems())
        break

      case pathname.startsWith('/items/new'): {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams()

        yield put(categoryActions.fetchCategories())
        yield put(partnerActions.fetchPartners({ offset, limit, filter, sortBy, sortDirection }))
        break
      }

      case pathname.startsWith('/items'): {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams()

        yield put(partnerActions.clear())
        yield put(categoryActions.fetchCategories())
        yield put(partnerActions.fetchPartners({ offset, limit, filter, sortBy, sortDirection }))

        const item = yield select(itemSelectors.selectItem)

        if (item?.id === +id) {
          break
        }

        yield put(itemActions.fetchItem({ id }))
        break
      }

      // TAG
      case pathname === '/tags':
        yield put(tagActions.clearTag())
        yield put(tagActions.fetchTags())
        break
      case pathname.startsWith('/tags/new'):
        break
      case pathname.startsWith('/tags'):
        const tag = yield select(tagSelectors.selectTag)

        if (tag?.id === +id) {
          break
        }

        yield put(tagActions.fetchTag({ id }))
        break

      // QUOTE
      case pathname === '/quotes': {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const currentUser = (yield select(profileSelectors.selectRoot)).user

        // yield put(quoteActions.clearQuotes())
        // yield put(quoteActions.setOffset(0))
        const { offset, limit } = getPaginationParams()

        if (currentUser?.type === UserType.Admin) {
          const { search, filterType } = getQueryParams({
            pageSize: currentUser?.type === UserType.Partner ? 12 : undefined,
          })

          yield put(
            quoteActions.fetchQuoteRequests({
              limit,
              offset,
              search,
              filterType,
              statuses: [
                QuoteRequestStatus.Accepted,
                QuoteRequestStatus.Pending,
                QuoteRequestStatus.Rejected,
              ],
              types: [QuoteType.Direct, QuoteType.ForYou],
              sortBy: 'createdAt',
              sortDirection: 'DESC',
            }),
          )
        } else {
          yield put(
            quoteActions.fetchQuoteRequests({
              status: QuoteRequestStatus.Pending,
              types: [QuoteType.Direct, QuoteType.ForYou],
              limit,
              offset,
              sortBy: 'createdAt',
              sortDirection: 'DESC',
            }),
          )
        }

        break
      }
      case pathname === '/quotes/sent': {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const { offset, limit } = getPaginationParams()

        yield put(quoteActions.clearQuoteResponse())
        yield put(quoteActions.clearQuoteRequest())
        yield put(
          quoteActions.fetchQuoteRequests({
            statuses: [QuoteRequestStatus.Accepted, QuoteRequestStatus.Rejected],
            limit,
            offset,
            sortBy: 'createdAt',
            sortDirection: 'DESC',
          }),
        )

        break
      }
      case pathname.startsWith('/quotes'): {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        if (pathname.endsWith('/need')) {
          yield put(needActions.seenUserNeed({ id }))
          yield put(needActions.fetchUserNeedForPartner({ id }))
          break
        }

        if (pathname.endsWith('/send')) {
          yield put(profileActions.fetchProfile())
          yield put(needActions.fetchUserNeedForPartner({ id }))
          break
        }

        if (pathname.endsWith('/view')) {
          const currentUser = (yield select(profileSelectors.selectRoot)).user
          if (currentUser?.type === UserType.Partner) {
            yield put(quoteActions.seeQuoteRequest({ id }))
          }

          yield put(quoteActions.fetchQuoteRequest({ id }))
        }

        const matchQuoteSent = matchPath(pathname, {
          path: '/quotes/:id([0-9a-zA-Z]{24})/sent',
          strict: true,
          exact: true,
        })

        if (matchQuoteSent) {
          const { id } = matchQuoteSent.params

          yield put(quoteActions.fetchQuoteRequest({ id }))
        }

        if (pathname.endsWith('/view-response')) {
          yield put(quoteActions.fetchQuoteRequest({ id }))
        }

        const quoteRequest = yield select(quoteSelectors.selectQuoteRequest)

        if (quoteRequest?.id.toString() === id.toString()) {
          if (pathname.includes('accept')) {
            yield put(profileActions.fetchProfile())
          }
          break
        }
      }

      // TAGGROUP
      case pathname === '/tag-groups':
        yield put(tagGroupActions.clearTagGroup())
        yield put(tagGroupActions.fetchTagGroups())
        break
      case pathname.startsWith('/tag-groups/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/tag-groups'):
        yield put(categoryActions.fetchCategories())

        const tagGroup = yield select(tagGroupSelectors.selectTagGroup)

        if (tagGroup?.id === +id) {
          break
        }

        yield put(tagGroupActions.fetchTagGroup({ id }))
        break

      // USER
      case pathname === '/users': {
        const { limit, offset } = getPaginationParams()
        const { filter } = getQueryParams()

        yield put(userActions.clearUser())
        yield put(userActions.fetchUsers({ limit, offset, filter }))
        break
      }
      case pathname.startsWith('/users/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/users'):
        const user = yield select(userSelectors.selectUser)
        yield put(categoryActions.fetchCategories())

        if (user?.id === +id) {
          break
        }

        yield put(userActions.fetchUser({ id }))
        break

      // PARTNER
      case pathname === '/partners': {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams()
        const { status } = getQueryParams()

        yield put(partnerActions.clearPartner())
        yield put(
          partnerActions.fetchPartners({ offset, limit, filter, sortBy, sortDirection, status }),
        )

        break
      }
      case pathname.startsWith('/partners/new'):
        yield put(needActions.fetchAllNeeds())
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/partners'):
        yield put(categoryActions.fetchCategories())
        yield put(itemActions.clearItem())

        const partner = yield select(partnerSelectors.selectPartner)

        yield put(needActions.fetchAllNeeds())
        if (partner?.id === +id) {
          break
        }

        yield put(partnerActions.fetchPartner({ id }))
        yield put(needActions.clearNeed())

        yield put(itemActions.setPartner(id))
        yield put(itemActions.fetchItems())
        break

      // NEED
      case pathname === '/needs': {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams()

        yield put(needActions.clearNeed())
        yield put(needActions.fetchNeeds({ offset, limit, filter, sortBy, sortDirection }))

        break
      }

      case pathname.startsWith('/needs/new'):
        yield put(categoryActions.fetchCategories())
        break

      case pathname.startsWith('/needs'):
        yield put(categoryActions.fetchCategories())

        const need = yield select(needSelectors.selectNeed)

        if (need?.id === +id) {
          break
        }

        yield put(needActions.fetchNeed({ id }))
        yield put(needActions.fetchWeddingsByNeed({ id }))
        break

      // USER NEED
      case pathname === '/user-needs': {
        yield handleUserNeedStatusFetching(UserNeedStatus.Pending)
        break
      }

      case pathname === '/user-needs/approved': {
        yield handleUserNeedStatusFetching(UserNeedStatus.Accepted)
        break
      }

      case pathname === '/user-needs/archived': {
        yield handleUserNeedStatusFetching(UserNeedStatus.Archived)
        break
      }

      case pathname === '/user-needs/deleted': {
        yield handleUserNeedStatusFetching(UserNeedStatus.Deleted)
        break
      }

      case pathname.startsWith('/user-needs'): {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const matchViewUserNeed = matchPath(pathname, {
          path: '/user-needs/:id([0-9a-zA-Z]{24})/view',
        })

        if (matchViewUserNeed) {
          yield put(quoteActions.clearQuoteRequest())
          yield put(needActions.fetchUserNeedForAdmin({ id }))
          break
        }

        break
      }

      // COUPLE
      case pathname.startsWith('/couples'): {
        if (pathname === '/couples') {
          const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams({
            sort: 'createdAt,DESC',
          })

          yield put(coupleActions.fetchCouples({ offset, limit, filter, sortBy, sortDirection }))
          yield put(coupleActions.clearCouple())
        }

        const matchViewCouple = matchPath(pathname, {
          path: '/couples/:id([0-9a-zA-Z]{24})/view',
          strict: true,
          exact: false,
        })

        if (matchViewCouple) {
          const { id } = matchViewCouple.params

          yield putAsync(coupleActions.fetchCouple({ id }))

          const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams({
            sort: 'createdAt,DESC',
          })

          yield put(
            needActions.fetchUserNeedsForAdmin({
              offset,
              limit,
              filter,
              sortBy,
              sortDirection,
              couple: id,
            }),
          )
        }
        break
      }

      // CATEGORY
      case pathname === '/categories':
        yield put(categoryActions.clearCategory())
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/categories/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/categories'):
        yield put(categoryActions.fetchCategories())

        const category = yield select(categorySelectors.selectCategory)

        if (category?.id === +id) {
          break
        }

        yield put(categoryActions.fetchCategory({ id }))
        break

      // BLOG
      case pathname === '/blogs':
        yield put(blogActions.clearBlog())
        yield put(blogActions.fetchBlogs())
        break
      case pathname.startsWith('/blogs/new'):
        yield put(blogActions.fetchBlogs())
        break
      case pathname.startsWith('/blogs'):
        yield put(blogActions.fetchBlogs())

        const blog = yield select(blogSelectors.selectBlog)

        if (blog?.id === +id) {
          break
        }

        yield put(blogActions.fetchBlog({ id }))
        break

      // NOTIFICATION
      case pathname === '/notifications':
        yield put(notificationActions.clearNotification())
        yield put(notificationActions.fetchNotifications())
        break
      case pathname.startsWith('/notifications/send'):
        yield put(notificationActions.fetchNotifications())
        break
      case pathname.startsWith('/notifications'):
        yield put(notificationActions.fetchNotifications())

        const notification = yield select(notificationSelectors.selectNotification)

        if (notification?.id === +id) {
          break
        }

        yield put(notificationActions.fetchNotification({ id }))
        break

      // HIGHLIGHTS
      case pathname === '/highlights':
        yield put(highlightActions.clearHighlight())
        yield put(highlightActions.fetchHighlights())
        break
      case pathname.startsWith('/highlights/new'):
        yield put(highlightActions.fetchHighlights())
        break
      case pathname.startsWith('/highlights'):
        yield put(highlightActions.fetchHighlights())

        const highlight = yield select(highlightSelectors.selectHighlight)

        if (highlight?.id === +id) {
          break
        }

        yield put(highlightActions.fetchHighlight({ id }))
        break

      // CHAT
      case pathname.startsWith('/chat'):
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const matchConversations = matchPath(pathname, {
          path: '/chat',
          strict: true,
          exact: true,
        })

        if (matchConversations) {
          !isFirstRendering && (yield put(chatActions.fetchConversations()))
        }

        const matchConversation = matchPath(pathname, {
          path: '/chat/conversations/:id([0-9a-zA-Z]{24})',
          strict: true,
          exact: true,
        })

        if (matchConversation) {
          const { id } = matchConversation.params

          yield putAsync(quoteActions.clearQuoteRequest())
          yield putAsync(chatActions.fetchConversation({ id }))
          yield putAsync(chatActions.seeConversation({ id }))
        }

        const matchNewConversation = matchPath(pathname, {
          path: '/chat/conversations/new',
          strict: true,
        })

        if (matchNewConversation) {
          const queryParams = new URLSearchParams(location.search)
          const userId = queryParams.get('userId')

          if (userId) {
            yield put(userActions.clearUser())
            yield putAsync(userActions.fetchUser({ id: userId }))
          }
        }

        // if (matchNewConversation) {
        //   yield put(chatActions.clearConversation())
        // }
        break

      // SERVICE
      case pathname === '/availabilities':
        yield put(availabilityActions.clearAvailability())
        yield put(availabilityActions.fetchAvailabilities())
        break

      case pathname.startsWith('/availabilities'):
        const availability = yield select(availabilitySelectors.selectAvailability)

        if (availability?.id === +id) {
          break
        }

        yield put(availabilityActions.fetchAvailability({ id }))
        break

      // SIGN-UP
      case pathname.startsWith('/sign-up'):
        const matchSignUp = matchPath(pathname, {
          path: '/sign-up',
          strict: true,
          exact: true,
        })

        if (matchSignUp) {
          yield put(categoryActions.fetchCategories())
        }
        break

      // SIGN-IN
      case pathname === '/sign-in':
        yield put(authActions.clearSignIn())
        break

      // INSPIRATION
      case pathname === '/inspiration':
        yield put(inspirationActions.clearItem())
        yield put(inspirationActions.fetchItems())
        break

      case pathname === '/inspiration/new':
        yield put(tagActions.fetchTags())
        break

      case pathname.startsWith('/inspiration'):
        yield put(tagActions.fetchTags())
        yield put(inspirationActions.fetchItem({ id }))

        break

      case pathname === '/cover':
        yield put(coverActions.clearCover())
        yield put(coverActions.fetchCovers())

        break

      case pathname.startsWith('/cover'):
        if (id === 'new') {
          break
        }
        yield put(coverActions.fetchCover({ id }))

        break

      case pathname.startsWith('/token-history'): {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams({
          sort: 'createdAt,DESC',
        })

        yield put(
          transactionActions.fetchTransactions({ offset, limit, filter, sortBy, sortDirection }),
        )
        break
      }

      case pathname === '/conversations': {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams()

        yield put(
          conversationActions.fetchConversations({
            offset,
            limit,
            filter,
            sortBy,
            sortDirection,
            all: true,
          }),
        )
        break
      }

      case pathname.startsWith('/conversations'): {
        const { offset, limit } = getPaginationParams()

        if (pathname.endsWith('/view')) {
          yield putAsync(conversationActions.fetchConversationMessages({ id, offset, limit }))
        }
        break
      }

      case pathname === '/wallet': {
        yield put(profileActions.fetchProfile()) // TODO fetch only wallet
        break
      }

      case pathname.startsWith('/purchase-token/result'):
      case pathname.startsWith('/wallet/purchase-token/result'): {
        const queryParams = new URLSearchParams(window.location.search)
        const { authToken, r, s } = Object.fromEntries(new URLSearchParams(window.location.search))

        yield put(paymentActions.validateTransaction({ r, s, authToken }))
        break
      }

      case pathname === '/payments': {
        const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams()
        const { status } = getQueryParams()

        yield put(
          paymentActions.fetchPayments({ offset, limit, filter, sortBy, sortDirection, status }),
        )

        break
      }

      default:
        break
    }
  } catch (e) {
    console.log('locationChange Error: ', e)
  }
}

function* handleUserNeedStatusFetching(status) {
  const { filter, sortBy, sortDirection, offset, limit } = getPaginationParams({
    sort: 'createdAt,DESC',
  })
  const queryParams = getQueryParams()
  const complete = queryParams.complete === 'true'
  const category = queryParams.category

  yield put(
    needActions.fetchUserNeedsForAdmin({
      offset,
      limit,
      filter,
      sortBy,
      sortDirection,
      status,
      complete,
      category,
    }),
  )
  yield put(
    needActions.fetchUserNeedCategorySummary({
      status,
    }),
  )
}

const navigationSagas = [takeEvery(LOCATION_CHANGE, locationChange)]

export default navigationSagas
